import React, { memo, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { MainLayout } from 'layouts/main';
import { AdminLayout } from 'layouts/admin';

import { PermissionProvider } from 'containers/molecules/permission';
import { isAllowCharters, getIsAllowTS, SubscriptionProvider, SubscriptionProviderPage, getIsEnabledCRM } from 'containers/molecules/subscription';
import BlockAccessTSBanner from 'containers/molecules/tourscanner/BlockAccessTSBanner';

import { CRMPermissionProvider, getCrmTypes } from 'crms/utils';

import NavigationMenu from '../pages/components/NavigationMenu.jsx';

import book from './book';
import { PrivateRoute } from './providers/privateRoute';
import { withReloadRoute } from './providers/reloadRoute';
import { useAuth } from './hooks';

const {
  main,
  client,
  details,
  login,
  register,
  forget,
  fin,
  tourscanner,
  stat,
  marketing,
  news,
  editor,
  calendar,
  adminBooking,
  adminAccounting,
  // eslint-disable-next-line import/no-named-as-default-member
  admin,
  privacyPolicy
} = book;

// Private Pages
const MainPage = withReloadRoute(lazy(() => import('../pages/MainPage')));
const ClientPage = lazy(() => import('../pages/Client'));
const DeetailsPage = lazy(() => import('../pages/Details'));
const Fin = lazy(() => import('../pages/Fin'));
const Tourscanner = lazy(() => import('../pages/Tourscanner'));
const Statistic = lazy(() => import('../pages/Statistic'));
const Marketing = withReloadRoute(lazy(() => import('../pages/Marketing')));
const News = lazy(() => import('../pages/News'));
const SingleNews = lazy(() => import('../pages/News/children/Single'));
const Filebrowser = lazy(() => import('../pages/Editor/children/FileBrowser'));
const Calendar = lazy(() => import('../pages/Calendar'));
const AdminBooking = lazy(() => import('../pages/Booking'));
const CommissionPage = lazy(() => import('pages/components/CommissionPage'));
const AdminAccounting = lazy(() => import('../pages/Accounting'));
const Charters = lazy(() => import('../pages/Charters'));
const BusTicketsPage = lazy(() => import('../pages/BusTickets'));
const AirTicketsPage = lazy(() => import('../pages/AirTickets'));
const CoursesPage = lazy(() => import('../pages/Fin/children/Rates'));

// Public Pages
const Login = lazy(() => import('../pages/Login'));
const Register = lazy(() => import('../pages/Register'));
const Forget = lazy(() => import('../pages/Forget'));
const NotFoundPage = lazy(() => import('../pages/Error'));
const PublicOfferPage = lazy(() => import('../pages/PublicOffer'));
const PublicPrivacyPolicy = lazy(() => import('../pages/PublicPrivacyPolicy'));

const withSubscription = Component => {
  const WithPermission = ({ checkAccess }) => {
    return (
      <SubscriptionProvider checkAccess={checkAccess} nullable={false}>
        {isAccess => (isAccess ? <Component /> : <BlockAccessTSBanner />)}
      </SubscriptionProvider>
    );
  };

  return WithPermission;
};

const TourscannerWithSubscription = withSubscription(Tourscanner);
const ChartersWithSubscription = withSubscription(Charters);

export default memo(() => {
  const isAuth = useAuth();
  const { isNakanikuly, isTat } = getCrmTypes();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={main} />} />
        <Route exact path={`${main}/main`} render={() => <Redirect to={main} />} />
        <Route exact path={login} render={() => <Login />} />
        <Route exact path={register} render={() => <Register />} />
        <Route exact path={forget} render={() => <Forget />} />
        <Route exact path={editor.children.filebrowser} render={() => <Filebrowser />} />
        <Route
          path={admin}
          render={() => (
            <AdminLayout>
              <Switch>
                <PrivateRoute
                  path={adminBooking.path}
                  render={() => (
                    <PermissionProvider required={['ADMIN_CHILD_TF_BOOKINGS_EXTENDED']}>
                      <AdminBooking />
                    </PermissionProvider>
                  )}
                />
                <PrivateRoute
                  path={adminAccounting.path}
                  render={() => (
                    <PermissionProvider required={['ADMIN_CHILD_TF_BOOKINGS_EXTENDED']}>
                      <AdminAccounting />
                    </PermissionProvider>
                  )}
                />
                <NotFoundPage code={404} />
              </Switch>
            </AdminLayout>
          )}
        />
        { !isAuth && (
          <Route
            exact
            path={[tourscanner.children.offer, tourscanner.children.offerV2]}
            render={() => <PublicOfferPage />}
          />
        ) }
        { isNakanikuly && (
          <Route
            exact
            path={privacyPolicy.path}
            render={() => <PublicPrivacyPolicy />}
          />
        ) }
        <Route
          path={main}
          render={() => (
            <MainLayout>
              <Suspense fallback={<div />}>
                <Switch>
                  <PrivateRoute
                    exact
                    path={main}
                    render={() => (
                      <SubscriptionProviderPage checkAccess={getIsEnabledCRM}>
                        <MainPage />
                      </SubscriptionProviderPage>
                    )}
                  />
                  <PrivateRoute
                    path={client.path}
                    render={() => (
                      <SubscriptionProviderPage checkAccess={getIsEnabledCRM}>
                        <PermissionProvider required={['CLAIMS']}>
                          <ClientPage />
                        </PermissionProvider>
                      </SubscriptionProviderPage>
                    )}
                  />
                  <PrivateRoute path={details.path} render={() => <DeetailsPage />} />
                  <PrivateRoute
                    path={fin.path}
                    render={() => (
                      <SubscriptionProviderPage checkAccess={getIsEnabledCRM}>
                        <PermissionProvider oneOf={['ALL_PAYMENTS', 'OWN_PAYMENTS']}>
                          <Fin />
                        </PermissionProvider>
                      </SubscriptionProviderPage>
                    )}
                  />
                  <PrivateRoute
                    path={stat.path}
                    render={() => (
                      <SubscriptionProviderPage checkAccess={getIsEnabledCRM}>
                        <PermissionProvider oneOf={['CLAIM_STAT_OWN', 'CLAIM_STAT_ALL']}>
                          <Statistic />
                        </PermissionProvider>
                      </SubscriptionProviderPage>
                    )}
                  />
                  <PrivateRoute
                    path={marketing.path}
                    render={() => (
                      <SubscriptionProvider checkAccess={getIsEnabledCRM}>
                        <PermissionProvider required={['MAILING']}>
                          <Marketing />
                        </PermissionProvider>
                      </SubscriptionProvider>
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={news.path}
                    render={() => <News />}
                  />
                  <PrivateRoute
                    exact
                    path={news.children.single}
                    render={() => <SingleNews />}
                  />
                  <PrivateRoute
                    exact
                    path={calendar}
                    render={() => <Calendar />}
                  />
                  <PrivateRoute
                    exact
                    path={[book.commission, book.commissionAccess]}
                    render={() => (
                      <CRMPermissionProvider oneOf={['tat', 'easybooking', 'nakanikuly', 'multitour']}>
                        <CommissionPage />
                      </CRMPermissionProvider>
                    )}
                  />
                  <PrivateRoute
                    path={[
                      book.tourscanner.path,
                      book.charters.path,
                      book.rates.path,
                      book.busTickets.path,
                      book.airtickets.path,
                    ]}
                    render={() => (
                      <PermissionProvider required={['TOURSCANNER']}>
                        <NavigationMenu />
                        <Switch>
                          <Route render={() => <TourscannerWithSubscription checkAccess={getIsAllowTS} />} path={book.tourscanner.path} />
                          <Route render={() => <ChartersWithSubscription checkAccess={isAllowCharters} />} path={book.charters.path} />
                          {(isTat || isNakanikuly) && <Route component={BusTicketsPage} path={book.busTickets.path} /> }
                          {isTat && <Route component={AirTicketsPage} path={book.airtickets.path} /> }
                          <Route component={CoursesPage} path={book.rates.path} />
                        </Switch>
                      </PermissionProvider>
                    )}
                  />
                  <NotFoundPage code={404} />
                </Switch>
              </Suspense>
            </MainLayout>
          )}
        />
        <NotFoundPage code={404} />
      </Switch>
    </Suspense>
  );
});
